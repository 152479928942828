  import { Link} from 'react-router-dom';
  import 'animate.css';
  import { HashLink } from 'react-router-hash-link';



export default function Home(){
    return(
      <>
      <div className='fsMain text-center'>
        <div className='conthero'>
        <h1 className='sampsubt'>Growth through Innovation</h1>
          <p className='samppara'>Achieving 3 Outcomes: Growing Sales - Saving Time - Reduce Expenses</p>
        </div>
        <div className='contlogo'>
        <img className='imgcont' src='./images/clear.png' height={390}></img>
        </div>
      </div>

      
      <div className='bodymarg'>

      {/* ----------------------------------------------------------------------- */}
      <div className='secsec' id="link">
        <div className='left22'>
        <img className='rightimg' src='./images/i_about_us.png' height={400}></img>
        </div>

        <div className='right22'>
          <h2 className='htwo2'>CLEARSKY SOFTWARE</h2>
          <h3 className='htwosec1'>Pioneering the Future of AI-Powered Business Solutions</h3>
          <p className='parasec1'>For 15 years, ClearSky Software has been a trusted partner for businesses seeking to leverage technology for growth and success. We specialize in developing innovative and cost-effective software solutions tailored to the unique needs of small businesses. Our commitment to staying ahead of the technological curve has enabled us to consistently deliver cutting-edge solutions that empower our clients to thrive in an ever-evolving digital landscape. We understand that our clients rely on us to provide the technological foundation for their competitiveness and sustainability, and we take that responsibility seriously.
          </p>
        </div>
      </div>
              

      </div>

      {/* ----------------------------------------------------------------------- */}

      <div className='priceplan'>

      <div className='secsec2' id="link">
        <div className='left22'>
        <img className='rightimg pb-3' src='./images/grey2.png' height={306}></img>
        </div>

        <div className='right222'>
          <h2 className='htwo2'>THE AI REVOLUTION</h2>
          <h3 className='htwosec1'>A Paradigm Shift for Business</h3>
          <p className='parasec1'>Artificial intelligence is no longer a futuristic concept; it is rapidly transforming the business world as we know it. Companies that fail to recognize and embrace the transformative potential of AI risk being left behind. AI is not simply another technological advancement; it represents a paradigm shift that will fundamentally alter how businesses operate, compete, and innovate.<br/>The rise of the internet democratized information, giving those who could effectively harness and analyze data a significant advantage. AI takes this a step further by automating and enhancing data analysis, enabling businesses to extract deeper insights, predict future trends with greater accuracy, and make more informed decisions. Ignoring AI is akin to forfeiting a crucial competitive advantage in today's data-driven world.
          </p>
        </div>
      </div>

      </div>

    {/* ----------------------------------------------------------------------- */}

    <div className='bodymarg'>

      <div className='secsec' id="link">
        <div className='right2223'>
          <h3 className='htwosec1'>ClearSky's Approach to AI Development</h3>
          <div class="custom-divider"></div>
          <p className='parasec1'>At ClearSky, we believe that the best way to understand and harness the power of AI is through hands-on development and experimentation. Since March, we have assembled a dedicated team of AI specialists with expertise in various domains, including computer vision, natural language processing, and machine learning. Our team has been diligently developing a diverse portfolio of AI-powered applications designed to address a wide range of business needs.</p>
        </div>
      </div>

      <div className='aiadev'>
      <div className='aidev'>
        <div className='aiimg'>
        <img className='rightimg2' src='./images/con1.png' height={60}></img>
        </div>
        <div className='aip'>
          <h6>Computer Vision</h6>
          <p>Our computer vision applications are poised to revolutionize the real estate and construction industries. We are introducing AI technology that uses both generative AI and machine learning. We're teaching our model to recognize the various components of a home's exterior and interior, enabling the application to understand and visualize the changes you desire.</p>
        </div>
      </div>
      <div className='aidev'>
        <div className='aiimg'>
        <img className='rightimg2' src='./images/con2.png' height={60}></img>
        </div>
        <div className='aip'>
          <h6>Real-Time Communication</h6>
          <p>We are developing advanced real-time communication solutions. Our RTC platforms will integrate various components of AI, such as text-to-speech and speech-to-text, allowing you to communicate seamlessly in 10 different languages in real-time.</p>
        </div>
      </div>
      <div className='aidev'>
        <div className='aiimg'>
        <img className='rightimg2' src='./images/con4.png' height={60}></img>
        </div>
        <div className='aip'>
          <h6>Marketing Automation</h6>
          <p>Our comprehensive marketing automation platform leverages AI to empower businesses to optimize their marketing campaigns and achieve better results by automating repetitive tasks, personalizing customer experiences, and providing data-driven insights.</p>
        </div>
      </div>
      <div className='aidev'>
        <div className='aiimg'>
        <img className='rightimg2' src='./images/con3.png' height={60}></img>
        </div>
        <div className='aip'>
          <h6>Virtual Assistants</h6>
          <p>Our AI-powered virtual assistants are designed to streamline operations and enhance productivity by automating repetitive tasks, providing timely reminders and notifications, and answering routine inquiries, freeing up your valuable time to focus on more strategic initiatives.</p>
        </div>
      </div>
      <div className='aidev'>
        <div className='aiimg'>
        <img className='rightimg2' src='./images/con5.png' height={60}></img>
        </div>
        <div className='aip'>
          <h6>SEO and Local SEO</h6>
          <p>By leveraging AI for SEO and Local SEO, businesses can gain a significant advantage in attracting more organic traffic, improving their online visibility, and ultimately driving more leads and sales through automated content optimization, keyword research, and competitor analysis.</p>
        </div>
      </div>
      </div>


      </div>

      
    {/* ----------------------------------------------------------------------- */}


    <div className='bodymarg'>

    <div className='secsec3' id="link">
      <div className='right2223'>
      <h2 className='htwo2'>JOIN US</h2>
        <h3 className='htwosec1'>The AI Revolution with ClearSky's BETA Program</h3>
        <p className='parasec1'>We invite forward-thinking businesses to partner with us on our AI journey by joining our BETA program. As a BETA partner, you will have the opportunity to collaborate with our team of AI experts, provide valuable feedback on our developing solutions, and gain early access to cutting-edge AI technology that can transform your business. Oh and Save Thousands</p>
      </div>

    </div>
    <div className='btnout'>
        <Link className='btnlearn1' as={Link} to="/BetaProgram">Explore Our BETA Program</Link>
        <a href='https://calendly.com/rorydredhartclearskysoftware/30min?month=2025-02' className='btnlearn2' target="_blank">Book an AI Consultation</a>
      </div>


    </div>
    </>
    )
}

      {/* 
      <div className='priceplan'>

        <div className='pinakacateg'>
          <div className='categtitle' id="Services">
            <h2  className='htwo'>How do we impact your business?  </h2>
            <p className='para'>Our solutions focus on overcoming two business challenges. Growing Revenue, and Operating Efficiency.</p>
          </div>
          <div className='categ'>
            <div className='categ1'>
              <img className='cagimg' src='/images/categ1.png'></img>
              <h3>Growing Revenues</h3>
              <p className='para'>We are committed to accelerating your sales growth. Partner with us to transform your business and achieve unparalleled success in today's competitive market.</p>
              <Link className='btnlearn1' as={Link} to="/GrowingRevenues">Learn More</Link>
            </div>
            <div className='categ2'>
            <img className='cagimg' src='/images/categ2.png'></img>
            <h3>Operational Efficiency</h3>
              <p className='para'>Today’s businesses optimize all steps in the production of revenue through the adoption of technology and analysis.</p>
              <Link className='btnlearn2' as={Link} to="/OperationalEfficiency">Learn More</Link>
            </div>
          </div>
        </div>

      </div> */}


        {/* <h3 className='htwosec1'>The AI Revolution: A Paradigm Shift for Business</h3>
          <p className='parasec1'>Artificial intelligence is no longer a futuristic concept; it is rapidly transforming the business world as we know it. Companies that fail to recognize and embrace the transformative potential of AI risk being left behind. AI is not simply another technological advancement; it represents a paradigm shift that will fundamentally alter how businesses operate, compete, and innovate.  
          The rise of the internet democratized information, giving those who could effectively harness and analyze data a significant advantage. AI takes this a step further by automating and enhancing data analysis, enabling businesses to extract deeper insights, predict future trends with greater accuracy, and make more informed decisions. Ignoring AI is akin to forfeiting a crucial competitive advantage in today's data-driven world.
          </p> */}

      {/* <div className='secsec1' id="link">
      <div className='left222'>
          <h3 className='htwosec1'>ClearSky's Approach to AI Development</h3>
          <p className='parasec1'>At ClearSky, we believe that the best way to understand and harness the power of AI is through hands-on development and experimentation. Since March, we have assembled a dedicated team of AI specialists with expertise in various domains, including computer vision, natural language processing, and machine learning. Our team has been diligently developing a diverse portfolio of AI-powered applications designed to address a wide range of business needs.</p>
          <h5 className='hfive'>The AI Revolution: A Paradigm Shift for Business</h5>
          <ul className='listdot'>
            <li><span className='stroglist'>Computer Vision:</span> Our computer vision applications are poised to revolutionize the real estate and construction industries.</li>
            <li><span className='stroglist'>Real-Time Communication:</span> We are developing advanced real-time communication solutions. Our RTC platforms will integrate various components of AI that will provide a more enriched experience.</li>
            <li><span className='stroglist'>Virtual Assistants:</span> Our AI-powered virtual assistants are designed to streamline operations and enhance productivity by automating repetitive tasks, providing timely reminders and notifications, and answering routine inquiries.</li>
            <li><span className='stroglist'>Marketing Automation:</span> Our comprehensive marketing automation platform leverages AI to empower businesses to optimize their marketing campaigns and achieve better results. </li>
            <li><span className='stroglist'>SEO and Local SEO:</span> By leveraging AI for SEO and Local SEO, businesses can gain a significant advantage in attracting more organic traffic, improving their online visibility, and ultimately driving more leads and sales.</li>
          </ul>
          <h5 className='hfive'>Join the AI Revolution with ClearSky's BETA Program</h5>
          <p className='parasec1'>We invite forward-thinking businesses to partner with us on our AI journey by joining our BETA program. As a BETA partner, you will have the opportunity to collaborate with our team of AI experts, provide valuable feedback on our developing solutions, and gain early access to cutting-edge AI technology that can transform your business. Oh and Save Thousands</p>
          <h5 className='hfive'>Take the Next Step</h5>
          <div>
            <button className='btnbetai'>Explore Our BETA Program</button>
            <button className='btnbetai'>Book an AI Consultation</button>
          </div>
        </div>
      </div> */}

     {/* ------------------------------------- adoption ---------------------------------- */}
     
     {/* <div className='bodymarg'>

      <div className='addoption'>
        <div className='adoptitle'>
        <h2 className='htwo'>How to access $2,400 and Transform your Contracting business today!</h2>
        <p className='para'>The Government of Canada is providing small business grants (For a limited time) to eligible businesses</p>
         </div>
          <div className='adpot'>
            <div className='adpotmainimg'>
            <img className='adopimg' src='/images/adopt.png' height={350}></img>
            </div>
            <div className='adopt1'>
            <h5 className='hfive'>Contractors with Sales &gt; $30,000 or 1+ Employee </h5>
            <div className='bancomp'>
            <h6 className='para'>Do I qualify?</h6>
            <h5 className='hfive'>You Need the following 5 conditions:</h5>
            <ul>
              <li className='para'>Must be a for-profit business (including for-profit social enterprises and co-operatives) in Ontario</li>
              <li className='para'>Must be a registered or incorporated business</li>
              <li className='para'>Must be a consumer-facing business, meaning be able to be accessed by consumer or provide in person services to consumers. </li>
              <li className='para'>Must commit to maintaining a digital adoption strategy for six months after participation in the program</li>
              <li className='para'>Must consent to participating in follow-up surveys, sharing information with the Government of Canada (ISED and Statistics Canada), and having the name of the business published as a recipient of funding</li>
            </ul>
            <h5 className='hfive'>Benefits:</h5>
            <p className='para pb-2'> <span className='benecolor'>Digital Needs Assessment </span>
            Through this program, small business owners can receive a micro-grant worth up to $2,400 to help get their business online, give their e-commerce presence a boost, or digitalize business operations. The CDAP program is a reimbursement grant. Successfully grant recipients must incur costs upfront after they have received conditional approval. Funds are paid out to the recipient after they are able to provide the OCC with the required proof of payment documentation. Grant recipients must commit to maintaining their digital adoption strategy for at least six months.</p>
            <a className='talktous1' href="https://calendly.com/r-dredhartclearskymarketing/overview-of-construction-beta-program" target="_blank" rel="noopener noreferrer">
           Book an appointment</a>
            </div>
          </div>
       </div>
      </div>

      </div> */}


        {/* ---------------------------------- PRICE PLAN ------------------------------------------ */}
  
{/* 
        <div className='priceplan1' id="Prices">
          <div className='prcA'>
            <div className='prcA1'>
              <h1 className='hone'>Joining Our Beta Program & Unlock the Full potential of your contracting business </h1>
              <h6 className='hsix1'>Be a Trailblazer and Reap the Rewards!</h6>
              <p className='paraPRC'>Be among the first to experience our revolutionary software. Your invaluable feedback will shape the future of our applications, and in return, we're offering you an unbeatable package:</p>
              <ul className='paraPRC'>
                <li><strong className='prcbold'>VIP Treatment: </strong> Enjoy priority support  and direct access to our development team.</li>
                <li><strong className='prcbold'>Lifetime Savings: </strong> Get a 50% discount on our annual licensing fee, forever.</li>
                <li><strong className='prcbold'>Market Dominance: </strong> Secure exclusive rights to use our software in specific markets.</li>
              </ul><div>
              <Link className='learnMorebtn1' to='/moreinfo?category=Who%20is%20ClearSky&option=New%20Beta%20Program'>Learn More</Link>
              </div>
              
            </div>
            <div className='prcA2'>
                <img className='prcimg' src='./images/prcner.png' height={310}></img>
            </div>
          </div>
          <div className='prcAB'>
          <p className='paraPRC'>Our relationship begins with a conversation, we want to understand your business and the challenges you are facing. During the startup phase we will work with no more than 30 contracting businesses within targeted geographic areas. If you are interested in knowing more you may click the Talk to Us buttons at the top and bottom of the page.</p>
          <h6 className='prchsix'>BETA Plan includes Modules:</h6>
          </div>
         
          <div className='prcB'>
          <div className='PrcB1'>
            <h5 className='hfiveprc'>INTRODUCTORY</h5>
            <div className='prclist'>
              <div>
              <li>Initial Consultation</li>
              <li>New Website</li>
              <li>Infrastructure</li>
              <li>Analytics</li>  
              </div>
              <div className='lmbtn'>
                <Link className='learnMorebtn1' to='/moreinfo?category=Introductory%20Module&option=Initial%20Assessment,%20New%20Website,%20Infrastructure,%20and%20Analytics'>Learn More</Link>
              </div>
            </div>
          </div>
          <div className='PrcB2'>
            <h5 className='hfiveprc2'>SUPPORT</h5>
            <div className='prclist2'>
              <div>
              <li>Social Management</li>
              <li>Local SEO Support</li>
              <li>KPI Reporting</li>
              <li>Customer Engagement</li>
              </div>
              <div className='lmbtn'>
                <Link className='learnMorebtn2' to='/moreinfo?category=Support%20Module&option=Social%20Media%20Management,%20Local%20SEO,%20KPI,%20and%20Customer%20Engagement'>Learn More</Link>
              </div>
            </div>
          </div>
          <div className='PrcB1'>
            <h5 className='hfiveprc'>GROWTH & VISIBILITY</h5>
            <div className='prclist'>
              <div>
              <li>Analytics</li>
              <li>Local SEO</li>
              <li>On Page Optimization</li>
              <li>Off Page Optimization</li>
              <li>Social Media</li>
              </div>
              <div className='lmbtn'>
                <Link className='learnMorebtn3' to='/moreinfo?category=Growth%20and%20Visibility%20Module&option=Analytics'>Learn More</Link>
              </div>
            </div>
          </div>
          <div className='PrcB2'>
            <h5 className='hfiveprc2'>BUILDING CREDIBILITY</h5>
            <div className='prclist2'>
              <div>
              <li>Construction Library</li>
              <li>Ai & 3D designer</li>
              <li>Demo Room</li>
              <li>Reviews</li>
              </div>
              <div className='lmbtn'>
                <Link className='learnMorebtn4' to='/moreinfo?category=Building%20Credibility%20Module&option=Construction%20Library'>Learn More</Link>
              </div>
            </div>
          </div>
          <div className='PrcB1'>
            <h5 className='hfiveprc'>OPTIMIZE OPERATIONS</h5>
            <div className='prclist'>
              <div>
              <li>DMS</li>
              <li>Qualifying Prospects</li>
              <li>Molly Hammer</li>
              <li>CMS</li>
              </div>
              <div className='lmbtn'>
                <Link className='learnMorebtn5' to='/moreinfo?category=Optimize%20Operations%20Module&option=Document%20Management%20System'>Learn More</Link>
              </div>
            </div>
          </div>
        </div>
        </div> */}
