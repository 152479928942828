import { useState, useRef } from "react";
import React from "react"
import './Pages.css';

export default function BetaProgram(){
   const [sliderPosition, setSliderPosition] = useState(50);
  const sliderRef = useRef(null);

  const handleMouseMove = (e) => {
    if (!sliderRef.current) return;
    const { left, width } = sliderRef.current.getBoundingClientRect();
    let newPosition = ((e.clientX - left) / width) * 100;
    newPosition = Math.max(0, Math.min(100, newPosition));
    setSliderPosition(newPosition);
  };

  const handleTouchMove = (e) => {
    if (!sliderRef.current) return;
    const touch = e.touches[0];
    handleMouseMove({ clientX: touch.clientX });
  }; 

 return(
    <>

      <div className="wow">
         <div className="apt">
            <h2>AI Technologies that will <span>WOW</span> you!</h2>
         </div>
      </div>

      <section className="sectionf">

      <div className="Feat1">
      <div
            className="featimage"
            ref={sliderRef}
            onMouseMove={handleMouseMove}
            onTouchMove={handleTouchMove}>
            <img src="/images/after1.png" className="before-image"  alt="Before"/>

            <div className="after-image-wrapper" style={{ clipPath: `inset(0 ${100 - sliderPosition}% 0 0)` }}>
              <img src="/images/before1.png" className="after-image" alt="After" />
            </div>
            <div className="slider-line" style={{ left: `${sliderPosition}%` }}>
              <div className="slider-handle"></div>
            </div>
          </div>
         <div className="featcontent">
            <h4> AI Visualizer </h4>
            <p>Imagine being able to show before and after photos before the job is done. Our AI visualizer using advanced machine learning technology is able to transform your old view of your home into a renovated view incorporating new materials that you select. How does it work? Upload an image and view your new metal roof, or go from siding to brick.  The AI Visualizer is a perfect tool for exterior contractors, landscapers, driveway contractors, patio builders, real estate agents.</p>
            <p>Change roofs, doors, exterior walls, gable ends and garage doors in 30 seconds. </p>
            <p>In the coming months we will be adding interior capabilities,  changing living rooms, bathrooms, adding furniture to rooms and of course adding a number of segments to the exterior. (fascia, soffit, gutters, windows, chimneys, porches)</p>
            <p><strong>Perfect for:</strong> For Exterior Contractors, Landscapers, Driveway Contractors, Patio Builders,  Real Estate Companies, Interior Designers, </p>
            <a href='https://calendly.com/rorydredhartclearskysoftware/30min?month=2025-02' className='signup' target="_blank">Sign up for Demo</a>
         </div> 
       </div>   


       <div className="wowsec">

      <div className="Feat2">
         <div className="featimage">
         <img src="./images/PAT5.png" ></img>
         </div>
         <div className="featcontent1">
            <h4> AI Assistant </h4>
            <p>Introducing your companies AI Assistant. Molly is trained to answer questions about your business.  Text or Speech . You train the AI with your content it will respond to your customers based on the information you provide.  The perfect assistant who is versed in 10 different languages.</p>
            <p>AI Assistant can perform routine tasks:  Billing, Invoicing, Reminders, Introductions, Respond to emails, publish content to social media. </p>
            <p><strong>Perfect for:</strong> Tourism businesses, OEM’s, Associations, Car, Boats, ATV’s Dealerships, Complex Services  </p>
            <a href='https://calendly.com/rorydredhartclearskysoftware/30min?month=2025-02' className='signup' target="_blank">Sign up for Demo</a>
         </div>
       </div>   
       </div>   


       <div className="Feat1">
         <div className="featimage">
         <img src="./images/PAT4.png" ></img>
         </div>
         <div className="featcontent">
            <h4> ViewRoom </h4>
            <p>ViewRoom is an exciting application that utilizing Real Time Communication (RTC) internet communication protocols. WEBRTC allows your customers to engage with your business using video content and sharing this experience with friends, family or business associates. Customers will seamlessly enter the Viewroom, once inside they could invite others, invite the sales representative to answer questions.  Some added features, include our AI Assistant and our AI Visualizer. </p>
            <p><strong>Perfect for:</strong> Perfect for any business/manufacturer that has a complex sales process. </p>
            <a href='https://calendly.com/rorydredhartclearskysoftware/30min?month=2025-02' className='signup' target="_blank">Sign up for Demo</a>
         </div>
       </div>   


       <div className="wowsec">
      
       <div className="Feat2">
         <div className="featimage">
         <img src="./images/PAT3.png"></img>
         </div>
         <div className="featcontent1">
            <h4> CMS- with AI integration</h4>
            <p>Integrating AI into our CMS will be a game-changer for small businesses, offering a range of benefits that can help them compete more effectively and grow. Focusing only on Content Creation: 
            AI-powered writing tools: AI can assist in generating content ideas, writing drafts, and even optimizing existing content for SEO. This can save small businesses time and effort while ensuring high-quality, engaging content.
            Automated content generation: For certain types of content, like product descriptions or news summaries, AI can automate the creation process, freeing up staff for other tasks.
            Personalized content: AI can analyze user data to deliver personalized content experiences, increasing engagement and conversions.  
            The perfect solution for any small business who is struggling to compete in today’s digital landscape.</p>
            <p><strong>Perfect for:</strong> The perfect solution for any small business who is struggling to compete in today’s digital landscape.</p>
            <a href='https://calendly.com/rorydredhartclearskysoftware/30min?month=2025-02' className='signup' target="_blank">Sign up for Demo</a>
         </div>
       </div>   
       </div>   


       <div className="Feat1">
         <div className="featimage">
         <img src="./images/PAT2.png"></img>
         </div>
         <div className="featcontent">
            <h4> A2P </h4>
            <p>Exactly what is A2P (application to person). A2P is a powerful messaging tool for businesses to communicate with their customers quickly and efficiently. Our platform currently gathers text and form messages generated from your website. All messages will be filtered through our AI assistant. The AI will segment critical messages and disposition them to the right person, while responding to routine requests. 
            As mentioned we are gathering communication from text messages and form enquries, future releases we will expand our touch points to include:  email communication, comments, social posts and replies, incoming phone calls.  Our A2P will be connected to our AI Assistant. What does this mean. This will allow your AI Assistant to know the context of conversations with your business. Our AI assistant will know from the communications the status of this potential user becoming a paying customer, it will provide ongoing updates to the CRM.   </p>
            <a href='https://calendly.com/rorydredhartclearskysoftware/30min?month=2025-02' className='signup' target="_blank">Sign up for Demo</a>
         </div>
       </div>   


       <div className="wowsec">

       <div className="Feat2">
         <div className="featimage">
         <img src="./images/PAT1.png"></img>
         </div>
         <div className="featcontent1">
            <h4> Portal Development  </h4>
            <p>We are experts in developing community portals that serve a specific sector covering a geographic area.  We have integrated solutions that address all of the common challenges that a portal faces in its day to day operations. In our most recent project we have been tasked to update a 15 year old portal. Our content aggregator was a game changer. We put in place a global scraping tool that will identify relevant content,, our AI machine learning software will categorize and sub-categorize the content and place the article within the portal news section. This same AI technology will ensure that the members of the portal all have their own member sections synchronized with their websites. Members no longer have to update their membership sections. </p>
            <p><strong>Perfect for:</strong> Associations, Regional Tourism, Community Economic Development, 
            News Organizations </p>
            <a href='https://calendly.com/rorydredhartclearskysoftware/30min?month=2025-02' className='signup' target="_blank">Sign up for Demo</a>
         </div>
       </div>   
       </div>


    </section>

 </>
 )
}